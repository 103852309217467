import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VForm,{attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c(VCardText,[(!_vm.showNewPassword)?_c(VTextField,{attrs:{"id":"password","label":"Nova Senha","name":"password","type":"password","rules":_vm.passwordRules,"required":"","background-color":"white","solo":"","append-icon":"mdi-eye-off"},on:{"click:append":function($event){_vm.showNewPassword = true}},model:{value:(_vm.payload.senha),callback:function ($$v) {_vm.$set(_vm.payload, "senha", $$v)},expression:"payload.senha"}}):_vm._e(),(_vm.showNewPassword)?_c(VTextField,{attrs:{"id":"password","label":"Nova Senha","name":"password","type":"text","rules":_vm.passwordRules,"required":"","background-color":"white","solo":"","append-icon":"mdi-eye"},on:{"click:append":function($event){_vm.showNewPassword = false}},model:{value:(_vm.payload.senha),callback:function ($$v) {_vm.$set(_vm.payload, "senha", $$v)},expression:"payload.senha"}}):_vm._e(),_c('div',{staticClass:"mt-5"},_vm._l((_vm.especificacaoSenha),function(especificacao,index){return _c('div',{key:index,class:_vm.errosSenha.includes(especificacao.name) ? 'falta' : ''},[_vm._v(" - "+_vm._s(especificacao.title)+" ")])}),0)],1),_c(VCardActions,[_c(VRow,[_c(VCol,[_c('div',{staticClass:"text-center"},[_c(VBtn,{attrs:{"color":"secondary","type":"submit"}},[_vm._v(" Trocar Senha ")])],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }