<template>
  <v-form lazy-validation @submit.prevent="onSubmit">
    <v-card-text>
      <!-- <v-text-field
        label="Usuário"
        name="login"
        type="text"
        v-model="payload.email"
        :rules="userRules"
        required
        background-color="white"
        solo
      ></v-text-field> -->

      <v-text-field
        v-if="!showNewPassword"
        id="password"
        label="Nova Senha"
        name="password"
        type="password"
        v-model="payload.senha"
        :rules="passwordRules"
        required
        background-color="white"
        solo
        append-icon="mdi-eye-off"
        @click:append="showNewPassword = true"
      ></v-text-field>
      <v-text-field
        v-if="showNewPassword"
        id="password"
        label="Nova Senha"
        name="password"
        type="text"
        v-model="payload.senha"
        :rules="passwordRules"
        required
        background-color="white"
        solo
        append-icon="mdi-eye"
        @click:append="showNewPassword = false"
      ></v-text-field>

      <div class="mt-5">
        <div
          v-for="(especificacao, index) in especificacaoSenha"
          :key="index"
          :class="errosSenha.includes(especificacao.name) ? 'falta' : ''"
        >
          - {{ especificacao.title }}
        </div>
      </div>
    </v-card-text>
    <v-card-actions>
      <v-row>
        <v-col>
          <div class="text-center">
            <v-btn color="secondary" type="submit"> Trocar Senha </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";
import { userAPI } from "@/modules/user";
import {
  regexSenhaQuebrado,
  especificacaoLabels,
} from "@/helpers/senhaEspecificacao";

export default {
  data() {
    return {
      payload: {
        tokenRecuperacao: this.$route.params.token,
      },
      especificacaoSenha: especificacaoLabels,
      errosSenha: [],
      showNewPassword: false,

      regexSenhaQuebrado: regexSenhaQuebrado,
      passwordRules: [
        (v) => !!v || "O campo nova senha é obrigatório",
        (v) => {
          if (v && !this.regexSenhaQuebrado.totalRegex.test(v)) {
            this.errosSenha = [];
            !this.regexSenhaQuebrado.letraMinuscula.test(v) &&
              this.errosSenha.push("minuscula");
            !this.regexSenhaQuebrado.letraMaiuscula.test(v) &&
              this.errosSenha.push("maiuscula");
            !this.regexSenhaQuebrado.numero.test(v) &&
              this.errosSenha.push("numero");
            !this.regexSenhaQuebrado.especial.test(v) &&
              this.errosSenha.push("especial");
            v.length < 8 && this.errosSenha.push("caracteres");

            return "Senha fraca";
          }
          this.errosSenha = [];
          return true;
        },
      ],
      userRules: [(v) => !!v || "O campo usuário é obrigatório"],
      logo: this.$store.state.logo,
    };
  },
  methods: {
    ...mapActions(["setLoading"]),
    onSubmit() {
      this.setLoading(true);
      userAPI
        .trocarSenha(this.payload)
        .then((resp) => {
          if (!resp.data) {
            this.setLoading(false);
            this.$notification.error("Token inválido!", { timer: 10 });
          } else {
            this.setLoading(false);
            this.$router.push("/auth/login");
          }
        })
        .catch((resp) => {
          this.setLoading(false);
          this.$notification.error("Erro ao recuperar senha!", { timer: 10 });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.falta {
  color: red;
}
</style>

<style lang="scss">
@import "../style.scss";
</style>
