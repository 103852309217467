<template>
  <v-form lazy-validation @submit.prevent="onSubmit">
    <v-card-text>
      <v-text-field
        label="Email cadastrado"
        name="login"
        type="text"
        v-model="payload.email"
        background-color="white"
        solo
      ></v-text-field>
    </v-card-text>
    <v-card-actions>
      <v-row>
        <v-col>
          <div class="text-center text">
            <v-btn color="secondary" type="submit">
              Solicitar Recuperação de Senha
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-form>
</template>

<script>
import { mapActions } from "vuex";
import { userAPI } from "@/modules/user";

export default {
  data() {
    return {
      payload: {},
      logo: this.$store.state.logo,
    };
  },
  methods: {
    ...mapActions(["setLoading"]),
    onSubmit() {
      this.setLoading(true);
      userAPI
        .recuperarSenha(this.payload)
        .then((resp) => {
          this.setLoading(false);
          this.$emit("recuperado", true);
        })
        .catch((resp) => {
          this.setLoading(false);
          this.$notification.error("Erro ao recuperar senha!", { timer: 10 });
        });
    },
  },
};
</script>
<style lang="scss" scoped>
@media screen and (max-width: 770px) {
  .text {
    button {
      font-size: 12px;
    }
  }
}
</style>
