<template>
  <v-container class="fill-height" fluid id="login">
    <v-row v-if="isSala">
      <login-selo>
        <template v-slot:formulario>
          <login-form></login-form>
        </template>
      </login-selo>
    </v-row>
  </v-container>
</template>

<script>
import GenericLogin from "./LoginComponents/Login";
import LoginSelo from "./LoginComponents/LoginSelo.vue";
import { mapGetters } from "vuex";
import LoginForm from "./LoginComponents/Form";

export default {
  computed: {
    ...mapGetters(["isSala"]),
  },
  components: {
    GenericLogin,
    LoginSelo,
    LoginForm,
  },

  mounted() {
    setTimeout(() => this.$notification.removeAll(), 10);
  },
};
</script>

<style lang="scss">
@import "./style.scss";
</style>
