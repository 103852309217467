import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{staticClass:"fill-height",attrs:{"fluid":"","id":"recover-password"}},[(_vm.isSala)?[_c('login-selo',{scopedSlots:_vm._u([(_vm.recuperado === false)?{key:"formulario",fn:function(){return [_c('lost-password-form',{on:{"recuperado":(e) => (_vm.recuperado = e)}})]},proxy:true}:{key:"formulario",fn:function(){return [_c('h3',{staticClass:"seloTexto"},[_vm._v(" Solicitação recebida. Caso o e-mail informado esteja registrado e correto você receberá uma mensagem com link para criar uma nova senha. Verifique a caixa de entrada ou de SPAM do seu email. ")])]},proxy:true}],null,true)})]:_c(VRow,[_c(VCol,{attrs:{"sm":"8","offset-sm":"2","md":"6","offset-md":"3","lg":"4","offset-lg":"4"}},[(_vm.recuperado === false)?_c(VCard,{staticClass:"elevation-12",attrs:{"color":"primary"}},[_c('div',{staticClass:"logo"},[_c('img',{staticClass:"logoImg",attrs:{"src":'/assets/img/' + _vm.logo}})]),_c('lost-password-form',{on:{"recuperado":(e) => (_vm.recuperado = e)}})],1):_vm._e(),(_vm.recuperado === true)?_c(VCard,{staticClass:"elevation-12",attrs:{"color":"primary"}},[_c(VCardText,[_c('span',[_c(VImg,{attrs:{"src":'/assets/img/' + _vm.logo,"width":"350px"}})],1),_c('br'),_c('h3',[_vm._v(" Solicitação recebida. Caso o e-mail informado esteja registrado e correto você receberá uma mensagem com link para criar uma nova senha. Verifique a caixa de entrada ou de SPAM do seu email. ")])])],1):_vm._e()],1)],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }