<template>
  <v-form lazy-validation @submit.prevent="onLogin" id="loginForm">
    <v-card-text>
      <v-text-field
        label="Usuário (e-mail)"
        name="login"
        type="text"
        v-model="payload.email"
        :rules="userRules"
        required
        background-color="white"
        solo
      ></v-text-field>
      <v-text-field
        v-if="!showNewPassword"
        id="password"
        label="Senha"
        name="password"
        type="password"
        v-model="payload.senha"
        :rules="passwordRules"
        required
        background-color="white"
        solo
        append-icon="mdi-eye-off"
        @click:append="showNewPassword = true"
      ></v-text-field>
      <v-text-field
        v-if="showNewPassword"
        id="password"
        label="Senha"
        name="password"
        type="text"
        v-model="payload.senha"
        :rules="passwordRules"
        required
        background-color="white"
        solo
        append-icon="mdi-eye"
        @click:append="showNewPassword = false"
      ></v-text-field>
    </v-card-text>
    <v-card-actions>
      <v-row>
        <v-col>
          <div class="text-right text">
            <v-btn text color="accent" :href="'#/auth/lost-password'">
              NÃO SABE SUA SENHA? CLIQUE AQUI
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-actions>
    <v-card-actions>
      <v-row>
        <v-col>
          <div class="text-center">
            <v-btn color="secondary" type="submit" id="btnEntrar">
              Entrar
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-card-actions>
  </v-form>
</template>

<script>
import { mapActions, mapGetters } from "vuex";
import Auth from "@/Auth.js";
// import { configuracoesAPI } from "@/modules/configuracao";
// import { groupsPermissionAPI } from "@/modules/groups";

export default {
  computed: {
    ...mapGetters(["token", "forcedLogout", "isAT", "isSala"]),
  },
  data() {
    return {
      payload: {},
      passwordRules: [(v) => !!v || "O campo senha é obrigatório"],
      userRules: [(v) => !!v || "O campo usuário é obrigatório"],
      valid: true,
      expired: Auth.getItem("expired"),
      lastRoute: Auth.getItem("last_route") || null,
      showNewPassword: false,
    };
  },
  methods: {
    ...mapActions([
      "setToken",
      "setConfig",
      "setUser",
      "setIsLogged",
      "setLoading",
      "setAdmin",
      "setGroupsConfig",
      "setUf",
      "setConsultor",
      "setAnalista",
      "setAD",
      "setForcedLogout",
      "setFullAdmin",
    ]),
    onLogin() {
      this.setLoading(true);
      this.$http
        .post("api/login", this.payload)
        .then(async ({ data: user }) => {
          if (user) {
            this.setUser(user);
            this.setForcedLogout(false);
            if (user.email === "admin@admin.com.br") this.setFullAdmin(true);

            this.setToken(user.accessToken);

            this.$http.defaults.headers.common[
              "Authorization"
            ] = `Bearer ${this.token}`;

            // const config = await configuracoesAPI.get();
            // const groupPermission = await groupsPermissionAPI.getByGroup(
            //   user.grupo.usuarioGrupoId
            // );

            // var uf = config.data.filter((item) => item.nome === "sigla")[0]
            //   .valor;

            // this.setUf(uf);

            // this.setConfig(
            //   JSON.stringify(
            //     config.data.filter((item) => item.configuracaoId == 1)[0]
            //   )
            // );

            // this.setGroupsConfig(JSON.stringify(groupPermission.data));

            this.$notification.success("Logado com sucesso!", { timer: 3 });
            this.$forceUpdate();
            if (
              this.expired === true &&
              this.forcedLogout === false &&
              this.lastRoute !== "/auth/login"
            ) {
              Auth.setItem("expired", false);
              this.$router.push(this.lastRoute);
            } else {
              this.$router.push("/cidade");
            }
          } else {
            console.log("Ocorreu um erro: usuario retornado foi : ", user);

            this.$notification.error(
              "Aconteceu um problema, contate o administrador do sistema",
              { timer: 10 }
            );
          }
          this.setLoading(false);
        })
        .catch((err) => {
          console.log("Erro ao fazer login: ", err);
          this.setLoading(false);

          if (err.response && err.response.data.usuarioBloqueado) {
            this.$notification.error(err.response.data.message, {
              timer: 10,
            });
          } else if (err.response && err.response.data.credencialErrada) {
            this.$notification.error(
              "Por gentileza, verifique as credenciais digitadas",
              { timer: 5 }
            );
          } else {
            this.$notification.error(
              "Ocorreu um erro, entre em contato com o administrador do sistema.",
              { timer: 10 }
            );
          }
          this.setForcedLogout(true);
        });
    },
  },
};
</script>
<style lang="scss" scoped>
.text {
  a {
    white-space: normal;
  }
}
</style>
