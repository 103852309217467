<template>
  <v-col sm="8" offset-sm="2" md="6" offset-md="3" lg="4" offset-lg="4">
    <v-card class="elevation-12" color="primary">
      <div class="logo">
        <img :src="'/assets/img/' + logo" class="logoImg" />
      </div>
      <login-form></login-form>
    </v-card>
  </v-col>
</template>
<script>
import LoginForm from "./Form";
export default {
  components: {
    LoginForm,
  },
  data() {
    return {
      logo: this.$store.state.logo,
    };
  },
};
</script>
<style lang="scss" scoped>
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoImg {
  width: 350px;
  margin-top: 20px;
}
</style>
