<template>
  <v-container class="fill-height" fluid id="recover-password">
    <v-row>
      <login-selo v-if="isSala">
        <template v-slot:formulario>
          <RecoverPasswordForm></RecoverPasswordForm>
        </template>
      </login-selo>
      <v-col
        sm="8"
        offset-sm="2"
        md="6"
        offset-md="3"
        lg="4"
        offset-lg="4"
        v-else
      >
        <v-card class="elevation-12" color="primary">
          <div class="logo">
            <img :src="'/assets/img/' + logo" class="logoImg" />
          </div>
          <RecoverPasswordForm></RecoverPasswordForm>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LoginSelo from "./LoginComponents/LoginSelo.vue";
import RecoverPasswordForm from "./LostPasswordComponents/RecoverPasswordForm";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["isSala"]),
  },
  data() {
    return {
      logo: this.$store.state.logo,
    };
  },
  components: { LoginSelo, RecoverPasswordForm },
};
</script>
<style lang="scss" scoped>
.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.logoImg {
  width: 350px;
  margin-top: 20px;
}
</style>
