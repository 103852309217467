import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VForm,{attrs:{"lazy-validation":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c(VCardText,[_c(VTextField,{attrs:{"label":"Email cadastrado","name":"login","type":"text","background-color":"white","solo":""},model:{value:(_vm.payload.email),callback:function ($$v) {_vm.$set(_vm.payload, "email", $$v)},expression:"payload.email"}})],1),_c(VCardActions,[_c(VRow,[_c(VCol,[_c('div',{staticClass:"text-center text"},[_c(VBtn,{attrs:{"color":"secondary","type":"submit"}},[_vm._v(" Solicitar Recuperação de Senha ")])],1)])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }