<template>
  <v-container class="fill-height" fluid id="recover-password">
    <template v-if="isSala">
      <login-selo>
        <template v-slot:formulario v-if="recuperado === false">
          <lost-password-form
            @recuperado="(e) => (recuperado = e)"
          ></lost-password-form>
        </template>

        <template v-slot:formulario v-else>
          <h3 class="seloTexto">
            Solicitação recebida. Caso o e-mail informado esteja registrado e
            correto você receberá uma mensagem com link para
            criar uma nova senha. Verifique a caixa de entrada ou de SPAM do seu
            email.
          </h3>
        </template>
      </login-selo>
    </template>

    <v-row v-else>
      <v-col sm="8" offset-sm="2" md="6" offset-md="3" lg="4" offset-lg="4">
        <v-card
          class="elevation-12"
          color="primary"
          v-if="recuperado === false"
        >
          <div class="logo">
            <img :src="'/assets/img/' + logo" class="logoImg" />
          </div>
          <lost-password-form
            @recuperado="(e) => (recuperado = e)"
          ></lost-password-form>
        </v-card>
        <v-card class="elevation-12" color="primary" v-if="recuperado === true">
          <v-card-text>
            <span>
              <v-img :src="'/assets/img/' + logo" width="350px"> </v-img>
            </span>
            <br />
            <h3>
              Solicitação recebida. Caso o e-mail informado esteja registrado e
              correto você receberá uma mensagem com link para
              criar uma nova senha. Verifique a caixa de entrada ou de SPAM do
              seu email.
            </h3>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import LostPasswordForm from "./LostPasswordComponents/Form";
import LoginSelo from "./LoginComponents/LoginSelo";
import { mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters(["isSala"]),
  },
  components: {
    LostPasswordForm,
    LoginSelo,
  },
  data() {
    return {
      payload: {},
      recuperado: false,
      logo: this.$store.state.logo,
    };
  },
};
</script>
<style lang="scss" scoped>
@import "@/variables.scss";

.logo {
  display: flex;
  justify-content: center;
  align-items: center;
}

.seloTexto {
  position: relative;
  z-index: 1;
  height: 200px;
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 20px;
  text-shadow: 1px 1px 5px #000000ab;
}

@media screen and (max-width: 770px) {
  .seloTexto {
    text-shadow: 1px 2px 8px #000000;
  }
}

.logoImg {
  width: 350px;
  margin-top: 20px;
}
</style>
